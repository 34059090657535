@import "theme.scss";
@import "reset.scss";

body {
  background-color: $primary-background-color;
  font-family: "Lato", sans-serif;
  height: 100%;
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    height: 100%;
  }
}
