.expandable__proposal__content {
  height: 100%;
  width: 100%;
  line-height: 30px;
  

  header {
    padding: 10px;
  }

  section {
    padding: 20px;

    strong {
      font-size: 16px;
    }
    span {
      font-size: 14px;
    }

    .expandable__model__years {
      display: flex;
      flex-wrap: wrap;
      li {
        list-style: none;
        margin-left: 10px;
        padding: 0 5px;
        height: 20px;
        border: 1px solid #000000;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .expandable__photos__list {
      display: flex;
      overflow: hidden;
      img {
        margin-left: 20px;
        width: 100px;
        height: 100px;
        object-fit: cover;
        transition: all 0.2s ease-in-out;
        border-radius: 3px;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}
