.title-modal {
  font-size: 2.6rem;
  color: #000;
  text-align: center;
}

.sub-title-modal {
  font-size: 1.6rem;
  text-align: center;
  max-width: 500px;
  text-align: center;
  margin: 30px 0 30px 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.button--style {
  border: none !important;
  font-weight: 400 !important;
  background-color: transparent !important;
  p {
    font-size: 1.1rem;
  }
}
