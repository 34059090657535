@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

$primary-color: #ffcc00;
$secundary-color: rgb(51, 51, 51);
$primary-background-color: #ffffff;
$primary-background-dark-color: #000;

.btn__be {
  border-radius: 8px;
  width: 100%;
  height: 60px;
}

.container-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-button {
  width: 280px;
  margin: 10px 0;
}

.btn__pk--primary { 
  background-color: $primary-color !important ;
  p {
    color: #fff;
    font-size: 1.4rem;
  }
}

.btn__pk--secundary {
  background-color: $secundary-color !important;
  p {
    color: #fff;
    font-size: 1.4rem;
  }
}
